<template>
  <div class="extension-apply-container">
    <div class="top-container">
      <a-row class="mb2">
        <div class="layout-inline mb2">
          <div>
            <span class="label">延期申请单号：</span>
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.postponeSn"
              @keyup.enter="queryBtn"
            />
          </div>
          <div>
            <span class="label">订单号：</span>
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.orderSn"
              @keyup.enter="queryBtn"
            />
          </div>
          <div style="display: flex">
            <span class="label">工厂：</span>
            <a-input placeholder="请选择" v-model="factoryName">
              <a-icon slot="suffix" type="dash" @click="openModal('工厂')" />
            </a-input>
          </div>
        </div>
        <div class="layout-inline">
          <div>
            <span class="label mr3">申请日期：</span>
            <a-date-picker
              format="YYYY-MM-DD"
              :allowClear="false"
              style="width: 28.5rem"
              v-model="queryFrom.applyTime"
              @change="onDataChange"
            />
          </div>
          <div>
            <span class="label">审核状态：</span>
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="queryFrom.state"
            >
              <a-select-option
                v-for="(item, i) in stateList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="operationBtn">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </div>
      </a-row>
    </div>

    <div class="bottom-container">
      <a-button class="mb2" type="primary" @click="openModal('申请')"
        >申请</a-button
      >
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 360 }"
        :pagination="pagination"
      >
        <template slot="state" slot-scope="text">
          <span
            :style="{ color: `${getColor(getMenuName(stateList, text))}` }"
            >{{ getMenuName(stateList, text) }}</span
          >
        </template>
        <template slot="operation" slot-scope="text, record">
          <!-- <span
            class="hover"
            v-if="record.state === 1"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal('查看', record.postponeId, record.state)"
            >审核</span
          > -->
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal('查看', record.postponeId, record.state)"
            >查看</span
          >
        </template>
      </a-table>
    </div>

    <!-- 延期申请 -->
    <ApplyModal :applyModal="applyModal" v-if="applyModal" />
    <!-- 延期申请详情 -->
    <ApplyModalDetail
      v-if="applyModalDetail"
      :applyModalDetail="applyModalDetail"
      :config="configFrom"
    />
    <JumpAddress
      v-if="visibleModal"
      :visibleModal="visibleModal"
      @factoryData="handleFactory"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "延期申请单号",
    ellipsis: true,
    dataIndex: "postponeSn",
    scopedSlots: { customRender: "postponeSn" },
  },
  {
    title: "订单号",
    ellipsis: true,
    dataIndex: "orderSn",
    scopedSlots: { customRender: "orderSn" },
  },
  {
    title: "工厂名称",
    ellipsis: true,
    dataIndex: "factoryName",
    scopedSlots: { customRender: "factoryName" },
  },
  {
    title: "申请日期",
    ellipsis: true,
    dataIndex: "applyTime",
    scopedSlots: { customRender: "applyTime" },
  },
  {
    title: "状态",
    ellipsis: true,
    dataIndex: "state",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "@/utils/color";
import { getMenuName } from "@/utils/utils.js";
import ApplyModal from "./applyModal.vue";
import ApplyModalDetail from "./applyModalDetail.vue";
import JumpAddress from "./jumpAddress.vue";
import { API } from "@/apix/delay.js";
import moment from "moment";

export default {
  mixins: [pagination, mixins],
  components: {
    ApplyModal,
    ApplyModalDetail,
    JumpAddress,
  },
  data() {
    return {
      columns,
      data: [
        // {
        //   postponeSn: "dfdsfsddfasdds",
        //   orderSn: "1232343",
        //   factoryName: "房贷首付",
        //   applyTime: "2021-09-23",
        //   state: 0,
        // },
        // {
        //   postponeSn: "dfdsfsddfasdds",
        //   orderSn: "1232343",
        //   factoryName: "挂号费东辉",
        //   applyTime: "2021-09-23",
        //   state: 1,
        // },
        // {
        //   postponeSn: "dfdsfsddfasdds",
        //   orderSn: "1232343",
        //   factoryName: "官方代购",
        //   applyTime: "2021-09-23",
        //   state: 2,
        // },
      ],
      loading: false,
      queryFrom: {
        postponeSn: null, // 延期申请单号
        orderSn: null, // 订单号
        tenantId: null, // 工厂id
        applyTime: null, // 申请日期
        state: undefined, // 审核状态(0退回，1审核中，2通过，3不通过)
      },
      stateList: [
        // { name: "退回", value: 0 },
        { name: "审核中", value: 1 },
        { name: "通过", value: 2 },
        { name: "不通过", value: 3 },
      ],
      applyModal: false, // 延期申请弹框
      applyModalDetail: false, // 延期申请详情弹框
      visibleModal: false, // 工厂选择框
      factoryName: null, // 工厂名称
      configFrom: {
        type: null,
        id: null,
        title: "申请",
      },
    };
  },
  methods: {
    getColor,
    getMenuName,
    openModal(type, id, state) {
      if (type === "申请") {
        this.applyModal = true;
      } else if (type === "查看") {
        this.configFrom.id = id;
        this.configFrom.type = state;
        this.applyModalDetail = true;
      } else if (type === "工厂") {
        this.visibleModal = true;
      }
    },
    closeModal() {
      this.applyModal = false;
      this.applyModalDetail = false;
      this.visibleModal = false;
    },
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.postponeId;
          return i;
        });
        this.loading = false;
      });
    },
    // 工厂返回数据
    handleFactory(val) {
      this.factoryName = val.factoryName;
      this.queryFrom.tenantId = val.factoryId;
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.queryFrom.state = undefined;
      this.factoryName = null;
      this.getSourceData();
    },

    // 处理日期
    onDataChange(date) {
      this.queryFrom.applyTime = moment(date).format("YYYY-MM-DD");
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.layout-inline {
  display: flex;
  justify-content: space-between;
}
.top-container {
  height: 16rem;
}
// .bottom-container {
//   min-height: 83%;
// }
.hover {
  margin-right: 2rem;
}
.label {
  width: 14rem !important;
  text-align: right;
  line-height: 3.5rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.operationBtn {
  margin-left: 14rem;
  button {
    margin-left: 2rem;
  }
}
.mb2 {
  margin-bottom: 2rem;
}
.mr3 {
  margin-left: 2.7rem;
}
.ant-input {
  width: 28.5rem;
}
</style>
