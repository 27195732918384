<template>
  <div class="apply-modal-container">
    <a-modal
      :visible="applyModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      title="延期申请"
      :width="1000"
    >
      <a-form-model layout="inline" :label-col="{ span: 6 }">
        <div class="layout-inline mb1">
          <a-form-model-item label="延期申请单号" class="center">
            <a-input v-model="submitFrom.postponeSn" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item class="center">
            <span slot="label" class="star">订单号</span>
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="submitFrom.orderSn"
              @change="handleOrderSn"
            >
              <a-select-option
                v-for="(item, i) in orderSnList"
                :value="item"
                :key="i"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <a-form-model-item label="工厂名称" class="center">
            <a-input v-model="submitFrom.factoryName" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="订购数量" class="center">
            <a-input v-model="submitFrom.goodsNum" disabled></a-input>
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <a-form-model-item label="交货日期" style="width: 48%">
            <a-input v-model="submitFrom.timeDelivery" disabled></a-input>
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <label class="label star">延期原因：</label>
          <a-textarea
            placeholder="请输入"
            style="width: 80%"
            :auto-size="{ minRows: 4 }"
            maxlength="64"
            v-model="submitFrom.reason"
          ></a-textarea>
          <span class="lenNum">已输入{{ length }}/64</span>
        </div>
        <div class="layout-inline">
          <label class="label">上传附件：</label>
          <UploadFile v-model="submitFrom.applyFileList" />
          <span class="upload">单个文件不能超过10M </span>
        </div>
      </a-form-model>

      <a-divider />

      <div class="mb2">
        <span class="bold"></span>
        <span class="title">原计划情况</span>
      </div>

      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :key="data.key"
        bordered
        :scroll="{ y: 400 }"
        :pagination="false"
      >
        <template slot="productionDay" slot-scope="text, record">
          <a-switch v-model="record.productionDay" disabled />
        </template>
      </a-table>

      <div class="mb2 mt2">
        <span class="bold"></span>
        <span class="title">调整计划</span>
      </div>

      <div class="layout-inline mb1" style="justify-content: space-around">
        <div>
          <label class="label">计划开始日期：</label>
          <a-date-picker
            format="YYYY-MM-DD"
            v-model="submitFrom.startTime"
            @openChange="onOpenChange"
            @calendarChange="onCalendarChange"
            :disabledDate="disabledDate"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            :disabled="delayTimeDisable"
            @change="
              (date, dateString) => onDataChange(date, dateString, '开始')
            "
          />
        </div>
        <div>
          <label class="label">计划完成日期：</label>
          <a-date-picker
            v-model="submitFrom.endTime"
            format="YYYY-MM-DD"
            @openChange="onOpenChange"
            @calendarChange="onCalendarChange"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            :disabledDate="(current) => disabledDate(current, 'end')"
            :disabled="delayTimeDisable"
            @change="
              (date, dateString) => onDataChange(date, dateString, '结束')
            "
          />
        </div>
      </div>

      <a-table
        :columns="columnsAdjust"
        :data-source="dataAdjust"
        :loading="loading"
        :key="dataAdjust.key"
        bordered
        :scroll="{ y: 400 }"
        :pagination="false"
      >
        <template slot="productionDay" slot-scope="text, record">
          <a-switch
            v-model="record.productionDay"
            @click="handleSwitch(record)"
          />
        </template>
        <template slot="planComplete" slot-scope="text, record">
          <a-input-number
            v-model="record.planComplete"
            :disabled="!record.productionDay"
            style="width: 80%"
            :min="1"
            :precision="0"
          />
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "生产日期",
    ellipsis: true,
    dataIndex: "productionData",
  },
  {
    title: "生产日",
    ellipsis: true,
    dataIndex: "productionDay",
    scopedSlots: { customRender: "productionDay" },
  },
  {
    title: "计划完成数",
    ellipsis: true,
    dataIndex: "planComplete",
  },
  {
    title: "实际完成数",
    ellipsis: true,
    dataIndex: "realityComplete",
  },
];
const columnsAdjust = [
  {
    title: "生产日期",
    ellipsis: true,
    dataIndex: "productionData",
  },
  {
    title: "生产日",
    ellipsis: true,
    dataIndex: "productionDay",
    scopedSlots: { customRender: "productionDay" },
  },
  {
    title: "计划完成数",
    ellipsis: true,
    dataIndex: "planComplete",
    scopedSlots: { customRender: "planComplete" },
  },
];
import mixins from "@/mixins/mixins.js";
import UploadFile from "@/components/uploadFile";
import moment from "moment";
import { API } from "@/apix/delay.js";
import { tips } from "../../utils/tips";
export default {
  mixins: [mixins],
  props: {
    applyModal: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UploadFile,
  },
  data() {
    return {
      columns,
      columnsAdjust,
      data: [],
      dataAdjust: [],
      loading: false,
      adjustStartTime: null, // 调整计划日期范围的开始时间
      adjustEndTime: null, // 调整计划日期范围的结束时间
      stateList: [
        { name: "退回", value: 0 },
        { name: "审核中", value: 1 },
        { name: "通过", value: 2 },
        { name: "不通过", value: 3 },
      ],
      orderSnList: [],
      submitFrom: {
        postponeSn: null, // 延期申请单号
        postponeId: null, // 延期申请单号id
        orderSn: null, // 订单号
        factoryName: null, // 工厂名称
        tenantId: null, // 工厂id
        timeDelivery: null, // 交货日期
        goodsNum: null, // 订购数量
        reason: null, // 延期原因
        applyFileList: [], // 上传附件
        startTime: null, // 延期开始时间
        endTime: null, // 延期结束时间
        state: null, // 审核状态(0退回，1审核中，2通过，3不通过)
        orderPlanVOList: [], // 调整后的计划详情
      },
      disabledCurrent: null,
      disabledEnd: null,
      adjustmentDate: [], // 存放调整过后的可用计划日期
      delayTimeDisable: true, // 禁用延期时间选择
    };
  },
  computed: {
    length() {
      if (this.submitFrom.reason === null) {
        return 0;
      }
      return this.submitFrom.reason && this.submitFrom.reason.length;
    },
  },
  methods: {
    moment,
    handleOk() {
      if (this.submitFrom.orderSn === null) {
        this.$message.error("请选择订单号");
      } else if (this.submitFrom.reason === undefined) {
        this.$message.error("请输入延期原因");
      } else {
        // 校验调整之后的日期
        this.checkProduct(this.dataAdjust);
        this.checkDelayTime();

        this.submitFrom.orderPlanVOList = this.dataAdjust;
        API.addDelay(this.submitFrom).then((res) => {
          tips(res, "申请");
          if (res.success) {
            this.cancel();
            this.$parent.getSourceData();
          }
        });
      }
    },

    // 校验调整计划之后的生产日和计划完成数
    checkProduct(data) {
      let arr = data.filter((item) => item.productionDay);
      arr.forEach((item) => {
        if (!item.planComplete) {
          this.$message.error("请输入计划完成数");
          throw new Error("请输入计划完成数");
        }
      });
    },

    // 校验延期开始和结束是否填写
    checkDelayTime() {
      if (this.submitFrom.startTime === undefined) {
        this.$message.error("请输入延期开始时间");
        throw new Error("请输入延期开始时间");
      } else if (this.submitFrom.endTime === undefined) {
        this.$message.error("请输入延期结束时间");
        throw new Error("请输入延期结束时间");
      }
      return true;
    },

    onOpenChange() {
      // 清空禁用时间段的设置
      // this.disabledCurrent = null;
      this.disabledCurrent = moment(this.adjustStartTime);
      this.disabledEnd = moment(this.adjustEndTime);
    },
    onCalendarChange(dates) {
      // 获取手动选择的时间段起始值
      this.disabledCurrent = dates[0];
    },
    disabledDate(current, type) {
      if (type === "end") {
        return current && current < moment(this.disabledEnd);
      } else {
        return (
          (current && current < moment(this.disabledCurrent)) ||
          current > moment(this.disabledEnd)
        );
      }
    },
    onDataChange(date, dateString, type) {
      if (type === "开始") {
        this.submitFrom.startTime = dateString;
      } else if (type === "结束") {
        this.submitFrom.endTime = dateString;
      }
      if (this.submitFrom.startTime && this.submitFrom.endTime) {
        this.getalldays(this.submitFrom.startTime, this.submitFrom.endTime);
      }
    },

    // 禁用生产日后清空计划完成数
    handleSwitch(record) {
      record.planComplete = null;
    },

    // 生成延期申请单号
    delayProductNum() {
      API.delayProductNum().then((res) => {
        this.submitFrom.postponeSn = res.data;
      });
    },

    // 可用延期订单号列表
    delayOrderNum() {
      API.delayOrderNum().then((res) => {
        this.orderSnList = res.data;
      });
    },

    // 选择订单号
    handleOrderSn(val) {
      this.delayTimeDisable = false;
      this.delayOrderDetail(val);
    },

    // 根据订单号获取详情
    delayOrderDetail(orderSn) {
      API.delayOrderDetail(orderSn).then((res) => {
        if (res.success) {
          for (const key in this.submitFrom) {
            if (key === "postponeSn") {
              continue;
            } else {
              this.submitFrom[key] = res.data[key];
            }
          }
          this.data = res.data.orderPlanVOList;
          this.adjustStartTime = res.data.startDelayTime;
          this.adjustEndTime = res.data.endDelayTime;
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },

    // 对象克隆
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    //获取两日期之间日期列表函数
    getalldays(start, end) {
      this.adjustmentDate = [];
      this.dataAdjust = [];
      var startTime = new Date(start).getTime();
      var endTime = new Date(end).getTime();
      for (var k = startTime; k <= endTime; ) {
        this.adjustmentDate.push(
          moment(new Date(parseInt(k))).format("YYYY-MM-DD")
        );
        k = k + 24 * 60 * 60 * 1000;
      }

      let detail = {
        productionData: null,
        productionDay: true,
        planComplete: null,
      };

      this.adjustmentDate.forEach((item, i) => {
        this.dataAdjust.push(this.deepClone(detail));
        this.dataAdjust[i].productionData = item;
      });
    },
  },
  created() {
    this.delayProductNum();
    this.delayOrderNum();
  },
};
</script>

<style lang="scss" scoped>
.layout-inline {
  width: 100%;
  display: flex;
  position: relative;
  &.wrap {
    flex-wrap: wrap;
  }
}
.ant-input {
  width: 28.5rem;
}
.center {
  width: 50%;
}
.bold {
  height: 100%;
  background: #ff4600;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.mt2 {
  margin-top: 2rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mt2 {
  margin-top: 2rem;
}
.label {
  width: 11.3rem;
  text-align: right;
  line-height: 3.5rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.lenNum {
  position: absolute;
  right: 10rem;
  bottom: 1rem;
  font-size: 1rem;
}
.upload {
  color: rgba(51, 51, 51, 0.65);
  margin-left: 1rem;
  line-height: 4rem;
  font-size: 1.2rem;
}
</style>
